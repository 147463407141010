import React from "react"
import { Link } from "gatsby"

import Nav from "./nav"
import config from '../../data/config'

class BlogLayout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          marginTop: '60px',
          maxWidth: '42rem',
          padding: '2.625rem 1.3125rem',
        }}
      >
        <Nav menuLinks={config.menuLinks} />
        <main>{children}</main>
      </div>
    )
  }
}

export default BlogLayout
