const config = {
  menuLinks: [
    {
      name: 'Articles',
      link: '/articles',
    },
    {
      name: 'Newsletter',
      link: '/newsletter',
    },
    // {
    //   name: '💰 Salary Negotiation',
    //   link: '/salary-negotation/',
    // },
    // {
    //   name: '👋 Contact',
    //   link: '/contact/',
    // },
  ],
};

export default config