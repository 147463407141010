import { Link } from "gatsby"
import React from "react"
import elbaumLogo from '../../content/assets/elbaums-logo.svg'

export default function Nav(props) {
  const { menuLinks } = props
  return (
    <nav className="nav">
      <div className="nav-container">
        <div className="brand">
          <Link className="brand-link" to="/">
            <img src={elbaumLogo} className="favicon" alt="Coder Guy" />
            {/* <span className="text">Peter Elbaum</span> */}
          </Link>
        </div>
        <div className="links">
          { menuLinks.map(link => (
            <Link key={link.name} to={link.link} activeClassName="active">
              {link.name}
            </Link>
          ))}
          <a href="https://peterelbaum.gumroad.com/" target="_blank" rel="noopener noreferrer">Products</a>
        </div>
        {/* <div
          style={{
            display: `flex`,
          }}
        >
          <a className="social-link" rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/channel/UCPcyffOFc7YGyDgqmvZJs7Q" style={{ marginLeft: '15px', marginTop: '5px' }}><img src={youtubeLogo}/></a>
          <a className="social-link" rel="noopener noreferrer" target="_blank" href="https://github.com/elbaumpj" style={{ marginLeft: '15px', marginTop: '5px' }}><img src={githubLogo}/></a>
          <a className="social-link" rel="noopener noreferrer" target="_blank" href="https://twitter.com/peterelbaum" style={{ marginLeft: '15px', marginTop: '5px' }}><img src={twitterLogo}/></a>
          <a className="social-link" rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/peter-elbaum/" style={{ marginLeft: '15px', marginTop: '5px' }}><img src={linkedinLogo}/></a>
        </div>  */}
      </div>
    </nav>
  )
}